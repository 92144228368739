<template>
  <div class="container">

        <div class="robot">

        <div class="e1"></div>
        <div class="e2"></div>


    </div>

    <div class="feed-container">

      <transition-group name="list">
        <div class="feed-elem" v-for="feed in feeds" :key="feed.id"> 
          {{ feed.message }}
        </div>
      </transition-group>

    </div>



  

  </div>
</template>

<script>
const uuidv4 = require("uuid/v4")

import { TimelineMax } from 'gsap'

export default {
  
  data() {
    return {
      feeds: []
    }
  },

  methods: {
    
    hideRobot () {
      const tl = new TimelineMax({ repeat: 10, repeatDelay: 10 })

      tl
        .to('.robot', { 
          translateX: 147,
          translateY: -908,
          scale: 0.5,
          border: 0,
          height: 92,
          duration: 0.3
         })
         .to('.robot', { 
          translateX: 0,
          translateY: 0,
          scale: 1,
          border: 8,
          height: 150,
          duration: 0.3,
          delay: 5
         });
    },

    unhideRobot () {
      const tl = new TimelineMax()

      tl
        .to('.robot', { 
          translateX: 0,
          translateY: 0,
          scale: 1,
          border: 8,
          duration: 1.5
         });
    },
    
    initAnimation () {
      const tl = new TimelineMax({ repeat: 10, repeatDelay: 2 })

      tl.to('.e1, .e2', { scaleY: 0, duration: 0.1 })
      tl.to('.e1, .e2', { scaleY: 1, duration: 0.1 })

      const tl2 = new TimelineMax({ repeat: 10, repeatDelay: 15 })

      tl2.to('.e1, .e2', { translateX: -10, translateY: -5 })
      tl2.to('.e1, .e2', { translateX: 0, translateY: 0, delay: 5 })
      tl2.to('.e1, .e2', { translateX: 10, translateY: -5 })
      tl2.to('.e1, .e2', { translateX: 0, translateY: 0, delay: 5 })

      const tl3 = new TimelineMax({ repeat: 10, repeatDelay: 5 })

      tl3.to('.robot', { css: { className:'+=robot reverse' } })
      .to('.robot', { css: { className:'+=robot' }, delay: 2 })

      this.hideRobot()

      // tl3
      //   .to('.robot', { translateY: -150, scale: 0.7, borderWidth: 0, opacity: 0.5 })
      //   .to('.robot', { translateY: 0, scale: 1, delay: 5, borderWidth: 6, opacity: 1 })




    },
  },

  mounted () {
    this.initAnimation()
  },

  created () {
    const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

    const messages = [
      'Bonsoir @Alxios',
      'Aife merci pour le Follow',
      'Bienvenue a tous'
    ]

    setInterval(_ => {
      this.feeds.push({
        message: messages[rnd(0, 2)],
        id: uuidv4()
      })

      if (this.feeds.length > 3) {
        this.feeds.shift()
      }
    }, 2500)

  }

}
</script>

<style lang="scss" scoped>

.list-enter-active, .list-leave-active {
  transition: all 1s ease, height .5s;
}

.list-leave-to {
  opacity: 0;
}

.list-enter {
  opacity: 0;

  margin: 0 !important;
  height: 0 !important;
  padding: 0 16px  !important;

  transform: translateX(15px)
}

.container {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.feed-container span {
  margin-top: 64px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  transition: all .3s ease;
  
  .feed-elem {
    background: #f1f1f1e6;

    border-radius: 8px;

    text-align: left;

    margin: 8px 0;

    max-width: 354px;
    width: fit-content;

    display: flex;
    align-items: center;
    justify-content: left;

    color: rgb(27, 27, 27);

    padding: 10px 16px;

    font-size: 21px;
    font-weight: 500;
    letter-spacing: 1.4px;
    font-family: 'Poppins';

    white-space: nowrap;

    height: 48px;

    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.robot {

  position: relative;

  align-items: flex-end;
  justify-content: flex-end;

  $ratio: 1.15;
  $size: 175px;

  width: calc(#{$size} * #{$ratio});
  height: 150px;


  margin: 64px 64px;

  background: #00000088;

  border: 6px solid #000000;


  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 5px 10px rgba(0,0,0,0.22);

  border-radius: 75%;

  border-bottom-left-radius: 60%;
  border-bottom-right-radius: 60%;

  transition: .3s ease all;

  // transform: scale(0.5);

  &.reverse {
    border-radius: 60%;

    border-bottom-left-radius: 75%;
    border-bottom-right-radius: 75%;
  }

}

.e1 {
  position: absolute;

  height: 60px;
  width: 30px;

  background: #000000;

  transform: rotate(-2deg);

  right: 25%;
  top: 40px;

  border-radius: 50%;
}

.e2 {
  position: absolute;

  height: 60px;
  width: 30px;

  transform: rotate(2deg);

  background: #000000;

  left: 25%;
  top: 40px;

  border-radius: 50%;
}

</style>